import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Switch,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {
  useDeleteNotificationMessageByIdMutation,
  useFinishGameMutation,
  useGetGameStatsQuery,
  useQueueNotificationMessageMutation,
  useSendPushNotificationMessageMutation,
  useStartGameMutation,
} from 'app/api';
import Page from 'components/Page/Page';
import {useEventContext} from 'contexts/EventContext';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../../app/store';
import Card from '../../components/Card/Card';
import {PlayerStats} from '../../types';
import {FinishGameButton} from './FinishGameButton';
import {PlayerScoringModal} from './scoring/PlayerScoringModal';
import {SingleTeamScoring} from './scoring/SingleTeamScoring';
import {TwoTeamScoring} from './scoring/TwoTeamScoring';

interface ScoringV2Props {
}

export const ScoringV2: React.FC<ScoringV2Props> = ({}) => {
  const {canPerformEventAction} = useEventContext();
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerStats | undefined>(
    undefined
  );
  const [showBothTeams, setShowBothTeams] = useState<boolean>(false);
  const [overriding, setOverriding] = useState<boolean>(false);
  const {gameId} = useParams();
  const {
    data: gameStatsData,
    refetch: refetchGameStats,
    isLoading: isGameStatsLoading,
  } = useGetGameStatsQuery(gameId ? parseInt(gameId) : skipToken);
  const [startGameMutation] = useStartGameMutation();
  const [finishGameMutation] = useFinishGameMutation();
  const [queueNotificationMessage] = useQueueNotificationMessageMutation();
  const [sendPushNotificationMessage] = useSendPushNotificationMessageMutation();
  const [deletePushNotificationMessage] = useDeleteNotificationMessageByIdMutation();
  useAppSelector((store) => store.user);
  const buttonColor = useColorModeValue('orange', 'blue');
  const notifyButtonColor = useColorModeValue('teal', 'red');
  const toast = useToast();

  useEffect(() => {
    if (gameStatsData && selectedPlayer) {
      const player =
        gameStatsData.homeTeam.find(
          (p: PlayerStats) => p.id === selectedPlayer.id
        ) ||
        gameStatsData.awayTeam.find(
          (p: PlayerStats) => p.id === selectedPlayer.id
        );
      if (player) {
        setSelectedPlayer(player);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStatsData]);

  if (!gameStatsData) return <></>;

  const isScoreKeeper = canPerformEventAction('SCORE_KEEPER');
  const isAdmin = canPerformEventAction('EVENT_ADMIN');

  const editable = gameStatsData
    ? gameStatsData.game.played
      ? isAdmin
      : isScoreKeeper
    : false;

  async function handleNotifyPlayersButtonClicked() {
    if (gameStatsData != null && !gameStatsData.game.played) {
      const {homeTeamName, awayTeamName, courtName, playoff, eventId, id} = gameStatsData.game;
      const title = `${playoff ? 'Playoff' : 'Game'}: ${homeTeamName} vs ${awayTeamName}`;
      const body = `Your game is starting soon! Please make your way to ${courtName}.`;

      let queuedNotification;

      try {
        queuedNotification = await queueNotificationMessage({
          queueNotification: {
            title,
            body,
            subscriptionType: 'GAME',
            eventId,
            resourceId: id,
          },
        }).unwrap();

        await sendPushNotificationMessage(queuedNotification.id).unwrap();

        toast({
          title: 'Notification Sent',
          description: 'The push notification was sent successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error: any) {
        toast({
          title: 'Error Sending Notification',
          description: error.data?.message || 'An error occurred.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        if (queuedNotification) {
          await deletePushNotificationMessage(queuedNotification.id).unwrap();
        }
      }
    }
  }


  return (
    <Page>
      <Card>
        <HStack justify="space-between">
          <VStack>
            <Heading noOfLines={1}>
              {'#' +
                (gameStatsData.game.homeTeamDrawId ||
                  gameStatsData.game.homeTeamId) +
                ' ' +
                gameStatsData.game.homeTeamName +
                ' vs. ' +
                '#' +
                (gameStatsData.game.awayTeamDrawId ||
                  gameStatsData.game.awayTeamId) +
                ' ' +
                gameStatsData?.game.awayTeamName}
            </Heading>
            <Text>
              Game ID: <b>{gameStatsData.game.id}</b> | Court:{' '}
              <b>{gameStatsData.game.courtName}</b> |{' '}
              {new Date(gameStatsData.game.startTime).toLocaleTimeString()} -{' '}
              {new Date(gameStatsData.game.endTime).toLocaleTimeString()} |{' '}
              {gameStatsData.game.scoreKeeperName && 'Primary ScoreKeeper: '}
              {gameStatsData.game.scoreKeeperName && (
                <Tooltip label={gameStatsData.game.scoreKeeperName}>
                  <Avatar size="xs" name={gameStatsData.game.scoreKeeperName}/>
                </Tooltip>
              )}
            </Text>
          </VStack>
          <Stack direction={{base: 'column', md: 'row'}}>
            <Flex>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="scoringView">Show Both Teams</FormLabel>
                <Switch
                  id="scoringView"
                  checked={showBothTeams}
                  onChange={(e) => setShowBothTeams(e.target.checked)}
                />
              </FormControl>
            </Flex>
            {gameStatsData?.homeTeam.length === 0 &&
              gameStatsData?.awayTeam.length === 0 &&
              gameStatsData?.game.homeTeamId &&
              gameStatsData.game.awayTeamId && (
                <Button
                  colorScheme={buttonColor}
                  onClick={() => {
                    startGameMutation({id: gameStatsData.game.id}).then(() =>
                      refetchGameStats()
                    );
                  }}
                >
                  Start Game
                </Button>
              )}
            {gameStatsData &&
              !gameStatsData.game.played &&
              gameStatsData.game.homeTeamId &&
              gameStatsData.game.awayTeamId &&
              (<Button
                colorScheme={notifyButtonColor}
                onClick={async () => {
                  await handleNotifyPlayersButtonClicked();
                }}
              >
                Notify Players
              </Button>)}
            {gameStatsData &&
              !gameStatsData.game.played &&
              gameStatsData.homeTeam.length > 0 &&
              gameStatsData.awayTeam.length > 0 && (
                <FinishGameButton
                  isDisabled={!editable}
                  colorScheme={buttonColor}
                  onClick={() => {
                    finishGameMutation({id: gameStatsData.game.id}).then(() =>
                      refetchGameStats()
                    );
                  }}
                />
              )}
            {canPerformEventAction('EVENT_ADMIN') && (
              <Button
                onClick={() => {
                  setOverriding(!overriding);
                }}
              >
                {overriding ? 'Done' : 'Override'}
              </Button>
            )}
          </Stack>
        </HStack>
        {showBothTeams ? (
          <TwoTeamScoring
            gameStatsData={gameStatsData}
            editable={editable}
            overriding={overriding}
            setSelectedPlayer={setSelectedPlayer}
            isAdmin={isAdmin}
            refetchGameStats={refetchGameStats}
          />
        ) : (
          <SingleTeamScoring
            gameStatsData={gameStatsData}
            editable={editable}
            overriding={overriding}
            setSelectedPlayer={setSelectedPlayer}
            isAdmin={isAdmin}
            refetchGameStats={refetchGameStats}
          />
        )}
        <PlayerScoringModal
          isLoading={isGameStatsLoading}
          selectedPlayer={selectedPlayer}
          isOpen={!!selectedPlayer}
          onClose={() => {
            setSelectedPlayer(undefined);
          }}
          refetchGameStats={() => {
            refetchGameStats();
          }}
        />
      </Card>
    </Page>
  );
};
